<template>
    <div class="container">
        <PageHeader :title="`알림 받을 지역 선택`">
            <template v-slot:subtitle>
                <span style="font-size: 18px">선택한 지역에 새로운 의뢰가 등록되면 바로 알려드려요.</span>
            </template>
        </PageHeader>

        <div class="ui-border-line ui-h-0 ui-mb-2" />

        <div>
            <tag-box>
                <template v-for="(row, key) in locationList">
                    <li
                        :key="key"
                        :class="{
                            on: selectedLocation.includes(row),
                        }"
                        @click="() => selectLocation(row)"
                    >
                        {{ $lib.getLocationText(row) }}
                    </li>
                </template>
            </tag-box>
        </div>

        <div class="ui-border-line ui-h-0 ui-mb-4" />

        <footer-box
            submitText="설정하기"
            :submitCb="
                () => {
                    pushSetting()
                }
            "
            :submitDisabled="!validate"
        />
    </div>
</template>

<script>
import PageHeader from '@/components/common/PageHeader.vue'
import FooterBox from '@/components/common/FooterBox.vue'
import TagBox from '@/components/common/TagBox.vue'

export default {
    components: {
        PageHeader,
        FooterBox,
        TagBox,
    },
    data() {
        const userData = this.$store.state.user.userData
        const mainProfile = this.$store.state.user.mainProfile

        const selectedLocation: string[] = userData?.pushLocation?.split?.(',') ?? []

        if (selectedLocation.length === 0) {
            selectedLocation.push('0')
        }

        return {
            userData,
            mainProfile,
            selectedLocation,
            locationList: ['0', '11', '21', '22', '23', '24', '25', '26', '29', '31', '32', '33', '34', '35', '36', '37', '38', '39'],
        }
    },
    computed: {
        validate() {
            return this.selectedLocation.length > 0
        },
    },
    methods: {
        pushSetting() {
            if (!this.validate) return

            const pushGenre = this.userData.pushGenre
            const pushChatOn = this.userData.pushChatOn
            const pushLocation = this.selectedLocation.join(',')
            const userData = { pushOn: 'on', pushChatOn, pushGenre, pushLocation }

            const req = {
                method: 'put',
                url: `/user/pushSetting/on`,
                data: {
                    genre: pushGenre,
                    chat: pushChatOn,
                    location: pushLocation,
                },
            }

            this.$store.commit('setUserData', userData)

            this.$http(req)
                .then(({ data }) => {
                    log(...Object.values(req), data)

                    const resultData = this.$lib.resultCheck(data.resultData)

                    if (resultData.success) {
                        this.$router.replace('/setting', () => {
                            this.$toast('알림이 설정(ON)되었습니다.')
                        })
                    } else {
                        this.$alert(resultData)
                    }
                })
                .catch(log.error)
        },
        selectLocation(locationCode) {
            if (locationCode === '0') {
                this.selectedLocation = ['0']
                return
            }

            const index = this.selectedLocation.findIndex(val => val === locationCode)
            const isSelected = index !== -1

            if (isSelected) {
                this.selectedLocation.splice(index, 1)

                if (this.selectedLocation.length === 0) {
                    this.selectedLocation.push('0')
                }
                return
            }

            this.selectedLocation.push(locationCode)
            this.selectedLocation = this.selectedLocation.filter(row => row !== '0')
        },
    },
}
</script>

<style lang="scss" scoped></style>
