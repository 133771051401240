var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c("PageHeader", {
        attrs: { title: "알림 받을 지역 선택" },
        scopedSlots: _vm._u([
          {
            key: "subtitle",
            fn: function() {
              return [
                _c("span", { staticStyle: { "font-size": "18px" } }, [
                  _vm._v(
                    "선택한 지역에 새로운 의뢰가 등록되면 바로 알려드려요."
                  )
                ])
              ]
            },
            proxy: true
          }
        ])
      }),
      _c("div", { staticClass: "ui-border-line ui-h-0 ui-mb-2" }),
      _c(
        "div",
        [
          _c(
            "tag-box",
            [
              _vm._l(_vm.locationList, function(row, key) {
                return [
                  _c(
                    "li",
                    {
                      key: key,
                      class: {
                        on: _vm.selectedLocation.includes(row)
                      },
                      on: {
                        click: function() {
                          return _vm.selectLocation(row)
                        }
                      }
                    },
                    [_vm._v(" " + _vm._s(_vm.$lib.getLocationText(row)) + " ")]
                  )
                ]
              })
            ],
            2
          )
        ],
        1
      ),
      _c("div", { staticClass: "ui-border-line ui-h-0 ui-mb-4" }),
      _c("footer-box", {
        attrs: {
          submitText: "설정하기",
          submitCb: function() {
            _vm.pushSetting()
          },
          submitDisabled: !_vm.validate
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }